<template>

    <div class="ar-landing" :class="{'tablet' : tablet}">

        <img src="@/assets/images/ar-terrain/img-bg-mtn-mobile.webp" class="mtn">
        <h1>Planning</h1>
        <h2>Your<br>Route</h2>
        <div class="paragraph">
            <p>Climb Mount Everest</p>
            <p>In Augmented Reality</p>
        </div>
        <ar-button 
            @click.native="gotoOnBoarding"
            :label="labels.begin"
            :tablet="tablet"
            icon="arrow">   
        </ar-button>

    </div>

</template>


<script>
import ArButton from '@/components/ar-terrain/ArButton'

export default {

    name: 'OxygenCalculatorLanding',

    props: ['tablet'],

    components: {
        ArButton
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            labels: {
                begin: 'Begin'
            }
        }
    },

    computed: {

    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        /**
         * @function gotoOnBoarding
         * @description Button click event handler.
         * 
         */
        gotoOnBoarding() {
            this.$router.push({ name: 'ArOnBoarding' })
        }
    }
    
}

</script>


<style lang="scss" scoped>

.ar-landing {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.tablet {

        & h1 {
            font-size: 35px;
        }

        & h2 {
            font-size: 95px;
            line-height: 85px;
        }

        & .paragraph {
            bottom: 252px;

            & p:nth-child(1) {
                font-size: 33px;
            }

            & p:nth-child(2) {
                font-size: 30px;
            }
        }

        & button {
            bottom: 100px;
        }
    }

    & .mtn {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    & h1 {
        color: #0f005d;
        position: relative;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: $font-weight-bold;
        margin: 62px 0 0;

        @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) {
            margin: 30px 0 0;
        }

        @media only screen
        and (min-device-width : 360px)
        and (max-device-width : 640px)
        and (-webkit-min-device-pixel-ratio: 4) { 
            margin: 37px 0 0;
        }
    }

    & h2 {
        position: relative;
        text-transform: uppercase;
        background: linear-gradient(to right, #20215e, #3a67a3, #67becc);
        color: #22226b;
        font-size: 72px;
        line-height: 72px;
        font-weight: $font-weight-extrabold;
        letter-spacing: 3px;
        margin: 10px 0 0;
        user-select: none;
        display: inline-block;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }

    & .paragraph {
        position: absolute;
        bottom: 102px;

        & p {
            text-transform: uppercase;
            margin: 0;
        }

        & p:nth-child(1) {
            color: #0f005d;
            font-size: 18px;
            font-weight: $font-weight-bold;
        }

        & p:nth-child(2) {
            color: #3f75ad;
            font-size: 15px;
            font-weight: $font-weight-semibold;
        }
    }

    & button {
        position: absolute;
        bottom: 31px;
    }
}

</style>
